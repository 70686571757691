import React from 'react';
import { MdOutlineFileDownload } from 'react-icons/md';

const DocxDownload = ({ handleDownloadDocx, openModalDocx, dataEvent, isAiEnabled }) => {
    const handleButtonClick = (categories) => {
        if (!isAiEnabled) {
            handleDownloadDocx(
                dataEvent?.id,
                categories,
                'desc',
                false 
            );
        } else {
            openModalDocx({
                id: dataEvent?.id,
                categories,
                sort: 'desc',
            });
        }
    };

    return (
        <div className="z-50 mt-[7.5rem] -ml-9 absolute flex flex-col text-left rounded-md h-fit py-1 px-3 bg-white shadow-lg border border-gray-200 text-main">
            {[
                { label: 'General Discussions Docx', categories: ['General Discussions'] },
                { label: 'Chatroom Docx', categories: ['Chatroom KIQs'] },
                { label: 'My KIQs Docx', categories: ['My KIQs'] },
                { label: 'SnapIntel Docx', categories: ['SnapIntel'] },
                {
                    label: 'Event Docx',
                    categories: ['General Discussions', 'Chatroom KIQs', 'My KIQs', 'SnapIntel'],
                },
            ].map(({ label, categories }, index) => (
                <button
                    key={index}
                    className="text-xs flex items-center justify-between gap-2 cursor-pointer"
                    onClick={() => handleButtonClick(categories)}
                >
                    {label}{' '}
                    <span className="text-lg">
                        <MdOutlineFileDownload />
                    </span>
                </button>
            ))}
        </div>
    );
};

export default DocxDownload;
