import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { getPrompts } from 'services/request/Dashboard'

const context = createContext()

export const AiSummaryContextProvider = ({ children }) => {
    const [isAiEnabled, setIsAiEnabled] = useState(false)

    const { data } = useQuery({
        queryKey: ['prompts'],
        queryFn: getPrompts,
    })

    const promptQuery = useMemo(() => data, [data])

    useEffect(() => {
        if (promptQuery) {
            setIsAiEnabled(promptQuery?.aiEnabled)
        }
    }, [promptQuery])

    return (
        <context.Provider value={{ isAiEnabled, prompts: promptQuery }}>
            {children}
        </context.Provider>
    )
}

export const useAiSummaryContext = () => {
    if (!context) {
        throw new Error('Must wrap in the provider')
    }
    return useContext(context)
}
