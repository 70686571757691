import React from 'react';
import { MdOutlineFileDownload } from 'react-icons/md';

const PdfDownload = ({ handleDownloadPDF, openModal, dataEvent, isAiEnabled }) => {
    const handleButtonClick = (categories) => {
        if (!isAiEnabled) {
            handleDownloadPDF(
                dataEvent?.id,
                categories,
                'desc',
                false 
            );
        } else {
            openModal({
                id: dataEvent?.id,
                categories,
                sort: 'desc',
            });
        }
    };

    return (
        <div className="animate_fade_up z-50 mt-[7.5rem] -ml-28 absolute transition-all duration-300 cursor-pointer hover:bg-opacity-100 flex flex-col text-left rounded-md h-fit py-1 px-3 bg-white shadow-lg border border-gray-200 text-main">
            {[
                { label: 'General Discussions PDF', categories: ['General Discussions'] },
                { label: 'Chatroom PDF', categories: ['Chatroom KIQs'] },
                { label: 'My KIQs PDF', categories: ['My KIQs'] },
                { label: 'SnapIntel PDF', categories: ['SnapIntel'] },
                {
                    label: 'Event PDF',
                    categories: ['General Discussions', 'Chatroom KIQs', 'My KIQs', 'SnapIntel'],
                },
            ].map(({ label, categories }, index) => (
                <button
                    key={index}
                    className="text-xs flex items-center justify-between gap-2 hover:scale-105 transition-all duration-300 cursor-pointer"
                    onClick={() => handleButtonClick(categories)}
                >
                    {label}{' '}
                    <span className="text-lg">
                        <MdOutlineFileDownload />
                    </span>
                </button>
            ))}
        </div>
    );
};

export default PdfDownload;
