import Header from 'components/Header'
import { useAtomValue } from 'jotai'
import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { authAtom } from 'store/authAtom'
import { AiSummaryContextProvider } from 'store/context/AiSummaryContextProvider'

export const Container = () => {
    const auth = useAtomValue(authAtom)

    if (auth?.user?.role === 'admin' && auth?.user?.access === 1) {
        return (
            <React.Fragment>
                <AiSummaryContextProvider>
                    <Header>
                        <Outlet />
                    </Header>
                </AiSummaryContextProvider>
            </React.Fragment>
        )
    }

    return <Navigate to={'/unauthorized_access'} />
}
