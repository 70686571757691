import { axiosInstance } from '../../axios'

export const getDashboardApi = async () => {
    const response = await axiosInstance.post(process.env.REACT_APP_DASHBOARD)

    return response.data
}

export const getChatroomApi = async (id) => {
    const response = await axiosInstance.post(process.env.REACT_APP_DASHBOARD_CHATROOM, {
        event_id: id,
    })

    return response.data
}

export const getMyKIQSApi = async (id) => {
    const response = await axiosInstance.post(process.env.REACT_APP_DASHBOARD_MYKIQS, {
        event_id: id,
    })
    return response.data
}

export const getSnapIntelApi = async (id) => {
    const response = await axiosInstance.post(process.env.REACT_APP_DASHBOARD_SNAPINTEL, {
        event_id: id,
    })
    return response.data
}

export const globalSearchApi = async (searchQuery) => {
    const response = await axiosInstance.post(process.env.REACT_APP_DASHBOARD_GLOBAL_SEARCH, {
        input: searchQuery,
    })

    return response.data
}

export const getGeneralSnapIntelApi = async () => {
    const response = await axiosInstance.post(process.env.REACT_APP_DASHBOARD_GENERAL_SNAPINTEL)

    return response.data
}

export const getGroupsApi = async () => {
    const response = await axiosInstance.post(process.env.REACT_APP_DASHBOARD_GROUP)

    return response.data
}

export const getAnalyticsApi = async (event_id) => {
    const response = await axiosInstance.post(process.env.REACT_APP_DASHBOARD_ANALYTICS, {
        event_id: event_id,
    })

    return response.data
}

export const getLatestGenSnap = async (company_id) => {
    const response = await axiosInstance.post('/api/v1/dashboard/get_company_general_snapintel', {
        company_id: company_id,
    })

    return response.data
}

export const getAllPDFGenTabApi = async (data) => {
    const response = await axiosInstance.post('api/v1/companies/get_event_responses', {
        event_id: data.id,
    })
    return response.data
}

export const getGoogleSearch = async (data) => {
    const response = await axiosInstance.get(`api/v1/dashboard/google-search`, {
        params: {
            q: data.searchTerms,
            start: data.startIndex,
            sort: data.sort,
            searchType: data.searchType,
        },
    })
    return response.data
}

export const getPrompts = async () => {
    const response = await axiosInstance.get('api/v1/prompts/get')
    return response.data
}

export const createPrompts = async (promptData) => {
    const response = await axiosInstance.post('api/v1/prompts/create', promptData)
    return response.data
}
